import $http from "../../api/api.js"
import $public from "../../util/public.js"
export default {
	namespaced: true,
	state: {
		module: '消费',

		searchType: "vip",
		userInfo: {
			id: '',
			basicInfo: {},
			cardInfo: {},
			companyInfo: {},
			status: {},
			vipGrades: {},
			pets_info: [],
		},
		jurisdictionBtnIds: sessionStorage.getItem('btnIds') ? JSON.parse(sessionStorage.getItem('btnIds')) : [],
		// count
		virtualCount: { cardCount: 0, couponCount: 0 },
		orderCount: { taskCount: 0, reserveCount: 0, orderCount: 0, buyBackCount: 0 },

		jinriState: "",
		genjinState: "",
		yuyueState: "",
		yueState: "",
		xizaokaState: "",
		youhuiquanState: "",
		jifenState: "",
		shouyinState: "",
		huodongState: "",
		chongzhiState: "",
		huifangState: "",
		buyBackState: "",
		jiezhangdanState:"",
		chongzhixiaopiao:"",
		chanpinjiaqian:""
	},
	getters: {

	},
	mutations: {
		setJurisdictionBtnIds(state, ids) {
			state.jurisdictionBtnIds = ids
			sessionStorage.setItem('btnIds', JSON.stringify(ids))
		},
		setModule(state, num) {
			state.module = num
		},
		setSearchType(state, num) {
			state.searchType = num
		},
		setUserInfo(state, obj) {
			if (obj.id) {
				if (obj.pets_info === null) {
					obj.pets_info = []
				}
				state.userInfo = obj
			} else {
				state.userInfo = {
					basicInfo: {},
					cardInfo: {},
					companyInfo: {},
					status: {},
					vipGrades: {},
					pets_info: [],
				}
			}
		},
		setVirtualCount(state, obj) {
			if (obj === null) {
				state.virtualCount = { cardCount: 0, couponCount: 0 }
			} else {
				state.virtualCount = obj
			}
		},
		setOrderCount(state, obj) {
			if (obj === null) {
				state.orderCount = { taskCount: 0, reserveCount: 0, orderCount: 0 }
			} else {
				state.orderCount = obj
			}
		},
		setJinriState(state, num) {
			state.jinriState = num
		},
		setGenjinState(state, num) {
			state.genjinState = num
		},
		setJiezhangdanState(state, num) {
			state.jiezhangdanState = num
		},
		setChongzhixiaopiaoState(state, num) {
			state.chongzhixiaopiaoState = num
		},
		setChanpinjiaqianState(state, num) {
			state.chanpinjiaqianState = num
		},
		setYuyueState(state, num) {
			state.yuyueState = num
		},
		setYueState(state, num) {
			state.yueState = num
		},
		setXizaokaState(state, num) {
			state.xizaokaState = num
		},
		setYouhuiquanState(state, num) {
			state.youhuiquanState = num
		},
		setJifenState(state, num) {
			state.jifenState = num
		},
		setShouyinState(state, num) {
			state.shouyinState = num
		},
		setHuodongState(state, num) {
			state.huodongState = num
		},
		setChongzhiState(state, num) {
			state.chongzhiState = num
		},
		setHuifangState(state, num) {
			state.huifangState = num
		},
		setBuyBackState(state, num) {
			state.buyBackState = num
		},
	},
	actions: {
		// 获取回购信息
		getBuyBack({ state, commit }) {
			let userInfo = state.userInfo
			if (!$public.isNull(userInfo.id) && !$public.isNull(userInfo.pets_info)) {
				let ids = []
				for (let i = 0; i < userInfo.pets_info.length; i++) {
					ids.push(userInfo.pets_info[i].id)
				}
				$http.post("/business/BuyBack/PetList", ids).then(res => {
					if (res.code === 0) {
						for (let i = 0; i < res.data.length; i++) {
							userInfo.pets_info[i].bugBackList = res.data[i]
						}
						commit("setUserInfo", userInfo)
					}
				})
			}
		},
		getCount({ state }) {
			// 获取洗美卡和优惠券数量
			if (state.userInfo.id) {
				let params = {
					MemberId: state.userInfo.id,
				}
				$http.get("/customer/Member/GetServiceCount", params).then(res => {
					if (res.code === 0) {
						state.virtualCount = res.data
					}
				})
			}
			// 获取今日订单 待跟进 待受理预约数量
			let params1 = {}
			$http.get("/business/Platform/Count", params1).then(res => {
				if (res.code === 0) {
					state.orderCount = res.data
				}
			})
		},
		// 获取产品可参加的活动
		getActivityOfProduct({ state }, obj) {
			let userInfo = state.userInfo
			let tableData = obj.tableData
			let paramList = []
			for (let i = 0; i < tableData.length; i++) {
				// let petClass=-1
				if (tableData[i].petClass == 'cat') {
					tableData[i].petClass = 1
				} else if (tableData[i].petClass == 'dog') {
					tableData[i].petClass = 2
				} else if (tableData[i].petClass == 'yichong') {
					tableData[i].petClass = 3
				}
				paramList.push({
					"paramId": tableData[i].productId,
					"brand": tableData[i].brand,
					"categoryId": tableData[i].secondCategoryId,
					"productType": tableData[i].productType,
					"petClass": tableData[i].petClass, // -1：全部  1:猫  2：犬  3：异
				})
			}
			let params = {
				"userLevel": userInfo.vipGrades.grades,
				"newMember": userInfo.basicInfo.isNewMember ? 1 : 0,
				"userId": userInfo.id,
				"paramList": paramList,
			}
			return new Promise((resolve) => {
				$http.post('/business/Order/MatchActivity', params).then(r => {
					if (r.code == 0) {
						resolve(r.data)
					}
				})
			})
		},
	},
};
