import Vue from "vue";
import VueRouter from "vue-router";
import mendian from "./children/mendian.js"
import cangku from "./children/cangku.js"
import huiyuan from "./children/huiyuan.js"
import order from "./children/order.js"
import huodong from "./children/huodong.js"
import dianpu from "./children/dianpu.js"
import shuju from "./children/shuju.js"
import feedback from "./children/feedback.js"
import version from "./children/version.js";  //版本
import jiaocheng from "./children/jiaocheng.js"; 
import supply from "./children/supply.js"; 
import purchase from "./children/purchase.js"; 

Vue.use(VueRouter);
const router = new VueRouter({
	routes: [
		{
			path: "/",
			redirect:"/login",
		},
		{
			path: "/login",
			component: () => import("@/views/login/login.vue"),
		},
		{
			path: "/mendian",
			redirect:"/mendian/shouyin",
			component: () => import("@/views/frame/layout.vue"),
			children:mendian,
		},
		{
			path: "/order",
			redirect:"/order/orderList",
			component: () => import("@/views/frame/layout.vue"),
			children:order,
		},
		{
			path: "/cangku",
			redirect:"/cangku/chanpinList",
			component: () => import("@/views/frame/layout.vue"),
			children:cangku,
		},
		{
			path: "/huiyuan",
			redirect:"/huiyuan/huiyuanList",
			component: () => import("@/views/frame/layout.vue"),
			children:huiyuan,
		},
		{
			path: "/huodong",
			redirect:"/huodong/huodongList",
			component: () => import("@/views/frame/layout.vue"),
			children:huodong,
		},
		{
			path: "/dianpu",
			redirect:"/dianpu/xitong",
			component: () => import("@/views/frame/layout.vue"),
			children:dianpu,
		},
		{
			path: "/shuju",
			redirect:"/shuju/shuju",
			component: () => import("@/views/frame/layout.vue"),
			children:shuju,
		},
		{
			path: "/feedback",
			redirect:"/feedback/ideaList",
			component: () => import("@/views/frame/layout.vue"),
			children:feedback,
		},
		{
			path: "/feedback",
			redirect:"/feedback/ideaList",
			component: () => import("@/views/frame/layout.vue"),
			children:feedback,
		},  // 员工管理--版本编号
		{
		 path: "/version",
		 redirect: "/version/versionAdd",
		 component: () => import("@/views/frame/layout.vue"),
		 children: version,
	 },//  教程
	 {
		path: "/jiaocheng",
		redirect: "/jiaocheng/videoList",
		component: () => import("@/views/frame/layout.vue"),
		children: jiaocheng,
	},
	// 供应链
	{
		path: "/supply",
		redirect: "/supply/supplyHome",
		component: () => import("@/views/frame/layout.vue"),
		children: supply,
	},
		// 采购单
		{
			path: "/purchase",
			redirect: "/purchase/purchaseList",
			component: () => import("@/views/frame/layout.vue"),
			children: purchase,
		},
	
	]
});
export default router;
