import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';

import guahao from './children/guahao.js'
import mendian from './children/mendian.js'
import dictionary from './children/dictionary.js'
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
	modules: { guahao, mendian, dictionary },
	state: {
		userInfo: {},
		shopInfo: {},
		token: '',

		version: process.env.NODE_ENV === 'development' ? '5.1.000' : '1.1.100',

		curNav2_mendian: {},  // {title:item.title,path:item.path}
		curNav2_cangku: {},
		curNav2_version: {},
		curNav2_huiyuan: {},
		curNav2_order: {},
		curNav2_feedback: {},
		curNav2_dianpu: {},
		curNav2_huodong: {},
		curNav2_huifang: {},
		curNav2_shuju: {},
		curNav2_jiaocheng: {},
		curNav2_supply: {},
		curNav2_purchase: {},


		printUrl: "",

	},
	getters: {

	},
	mutations: {
		setUserInfo(state, num) {
			state.userInfo = num
			console.log(num, 'numnum');
			sessionStorage.setItem("companyId", num.companyInfo.id)
		},
		setShopInfo(state, num) {
			state.shopInfo = num
		},
		setToken(state, num) {
			state.token = num
		},
		set_printUrl(state, str) {
			state.printUrl = str;
		},
		setCurNav2_supply(state, num) {
			state.curNav2_supply = num;
		},
		setCurNav2_purchase(state, num) {
			state.curNav2_purchase = num;
		},
		setCurNav2_mendian(state, num) {
			state.curNav2_mendian = num
		},
		setCurNav2_cangku(state, num) {
			state.curNav2_cangku = num
		},
		setCurNav2_huiyuan(state, num) {
			state.curNav2_huiyuan = num
		},
		setCurNav2_order(state, num) {
			state.curNav2_order = num
		},
		setCurNav2_feedback(state, num) {
			state.curNav2_feedback = num
		},
		setCurNav2_dianpu(state, num) {
			state.curNav2_dianpu = num
		},
		setCurNav2_huodong(state, num) {
			state.curNav2_huodong = num
		},
		setCurNav2_shuju(state, num) {
			state.curNav2_shuju = num
		},
		setCurNav2_huifang(state, num) {
			state.curNav2_huifang = num
		},
		setCurNav2_version(state, num) {
			state.curNav2_version = num
		},
		setCurNav2_jiaocheng(state, num) {
			state.curNav2_jiaocheng = num
		},
	},
	actions: {

	},
	plugins: [createPersistedState({
		storage: window.sessionStorage,
		reducer(val) {
			return {
				userInfo: val.userInfo,
				shopInfo: val.shopInfo,
				token: val.token,
				curNav2_mendian: val.curNav2_mendian,
				curNav2_cangku: val.curNav2_cangku,
				curNav2_huodong: val.curNav2_huodong,
				curNav2_huifang: val.curNav2_huifang,
				curNav2_huiyuan: val.curNav2_huiyuan,
				curNav2_order: val.curNav2_order,
				curNav2_version: val.curNav2_version,
				curNav2_feedback: val.curNav2_feedback,
				curNav2_dianpu: val.curNav2_dianpu,
				curNav2_shuju: val.curNav2_shuju,
				curNav2_jiaocheng: val.curNav2_jiaocheng,
				curNav2_supply: val.curNav2_supply,
				curNav2_purchase: val.curNav2_purchase,
				mendian: {
					module: val.mendian.module,
					userInfo: val.mendian.userInfo,
				},

			}
		}
	})]
});
